

































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';
import Review from '@/models/reviews/Review';
import ReviewChanges from '@/models/reviews/ReviewChanges';
import { getCurrentUserID } from '@/auth/AuthService';
import { ReviewStateEnum } from '@/enums/ReviewStateEnum';
import WysiwygEditor from '@/components/WysiwygEditor.vue';
import SaveReviewModal from '@/components/modals/Review/SaveReviewModal.vue';
import CreateDefectModal from '@/components/modals/Review/CreateDefectModal.vue';
import ReviewDefectComponent from '@/components/review/ReviewDefectComponent.vue';
import ReviewDefect from '@/models/reviews/ReviewDefect';
import ReviewDefectService from '@/services/ReviewDefectService';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import DeleteReviewDefectModal from '@/components/modals/Review/DeleteReviewDefectModal.vue';
import EditDefectModal from '@/components/modals/Review/EditDefectModal.vue';
import { DefectTypeEnum } from '@/models/reviews/DefectTypeEnum';
import { DefectServityEnum } from '@/models/reviews/DefectServityEnum';
import { BModal } from 'bootstrap-vue';
import CreateDefectHelpModal from '@/components/modals/Review/CreateDefectHelpModal.vue';
import DetailDefectModal from '@/components/modals/Review/DetailDefectModal.vue';

@Component({
  components: {
    DetailDefectModal,
    CreateDefectHelpModal,
    EditDefectModal,
    DeleteReviewDefectModal,
    ReviewDefectComponent,
    CreateDefectModal,
    SaveReviewModal,
    WysiwygEditor,
  },
})
export default class ReviewAttributeBar extends mixins(Toasts) {
  protected getState(state: boolean | null, value: string | null, maxLength: number): boolean | null {
    if (value && value.length > 0) {
      const lenCheck = value.length <= maxLength;
      if (!lenCheck) {
        return false;
      }
    }
    return state;
  }

  @Prop({ required: true })
  protected currentReview!: Review;

  @Prop({ required: true, type: Function })
  protected submit!: (reviewChanges: ReviewChanges) => void;

  @Prop({ required: true, type: Function })
  protected resetDefectsVisible!: () => void;
  @Prop({ required: true, type: Function })
  protected showDefectMarker!: (defect: ReviewDefect) => void;

  protected defectIdForDeletion = -1;
  protected reviewDefects: ReviewDefect[] = [];
  protected loaded = false;
  protected editDefect: ReviewDefect = new ReviewDefect(
    0.0,
    0.0,
    0.0,
    0.0,
    '',
    '',
    DefectTypeEnum.WRONG,
    DefectServityEnum.MINOR,
    -1
  );
  protected fieldStates: { comment?: boolean | null; state?: boolean | null } = {
    comment: null,
    state: null,
  };

  protected isReviewAlreadySubmitted(): boolean {
    return this.getStateString(this.currentReview.state) !== ReviewStateEnum.IN_REVIEW;
  }

  @Watch('currentReview')
  protected watchReview(): void {
    if (this.currentReview) {
      if (this.currentReview.comment) {
        this.enteredComment = this.currentReview.comment;
      } else {
        this.enteredComment = '';
      }
      this.enteredState = this.currentReview.state;
      this.getAllReviewDefects();
    }
  }

  protected enteredComment = '';
  protected enteredState: string | null = null;

  protected reviewStateOptions: string[] = Object.keys(ReviewStateEnum);

  protected currentUserID = '';

  mounted(): void {
    this.currentUserID = getCurrentUserID();
    if (this.currentReview) {
      this.getAllReviewDefects();
    }
  }

  protected getStateString(reviewState: string): string {
    return ReviewStateEnum[reviewState as keyof typeof ReviewStateEnum];
  }

  protected getStateFromString(reviewState: string): string {
    if (reviewState === 'Approved') {
      return 'OK';
    }
    if (reviewState === 'In Review') {
      return 'IN_REVIEW';
    }
    if (reviewState === 'Rejected') {
      return 'NOT_OK';
    }
    if (reviewState === 'Unclear') {
      return 'UNCLEAR';
    }
    return 'IN_REVIEW';
  }

  protected openDefectDeleteModal(id: number): void {
    this.defectIdForDeletion = id;
    this.$bvModal.show('delete-defect-modal');
  }

  protected deleteDefect(): void {
    if (this.defectIdForDeletion < 0) {
      this.showToast('Failed to delete', 'Could not delete review defects. Unknown defect.', 'danger');
      return;
    }

    ReviewDefectService.deleteReviewDefect(this.defectIdForDeletion)
      .then(() => {
        this.getAllReviewDefects();
        this.defectIdForDeletion = -1;
        this.showToast('Success', 'The defect has been deleted.', 'success');
      })
      .catch((backendError) => {
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast(
            'Failed to delete',
            'Could not delete review defects. ' + backendError.response.data.message,
            'danger'
          );
        }
      });
  }

  protected submitResult(state: ReviewStateEnum): void {
    if (this.isReviewOfCurrentUser(this.currentReview)) {
      if (this.validateFields()) {
        if (state !== ReviewStateEnum.IN_REVIEW) {
          this.$bvModal
            .msgBoxConfirm(
              "You cannot make additional changes to this review after submitting it with the state: '" + state + "'",
              {
                title: 'Submit Review Result',
                okVariant: 'primary',
                okTitle: 'Submit',
                cancelTitle: 'Cancel',
                hideHeaderClose: true,
                centered: true,
              }
            )
            .then((confirmationResponse) => {
              if (confirmationResponse) {
                if (this.submit) {
                  this.submit(
                    new ReviewChanges(this.getStateFromString(state) as ReviewStateEnum, this.enteredComment)
                  );
                }
              }
            })
            .catch(() => {
              this.$bvToast.toast('Oops, something failed...', {
                title: 'Unexpected behaviour',
                variant: 'danger',
                solid: true,
                autoHideDelay: 3000,
              });
            });
        } else {
          if (this.submit) {
            this.submit(new ReviewChanges(this.getStateFromString(state) as ReviewStateEnum, this.enteredComment));
          }
        }
      }
    } else {
      this.$bvToast.toast('You do not have the required rights.', {
        title: 'Action denied',
        variant: 'danger',
        solid: true,
        autoHideDelay: 3000,
      });
    }
  }

  protected isReviewOfCurrentUser(review: Review): boolean {
    return review.userId === this.currentUserID;
  }

  protected format(date: Date): string {
    return moment(date).format('DD.MM.YYYY HH:mm');
  }

  protected validateFields(): boolean {
    let result = true;
    if (this.enteredComment.length <= 0 || this.enteredComment.length > 5000) {
      this.fieldStates.comment = false;
      result = false;
    } else {
      this.fieldStates.comment = true;
    }
    this.fieldStates.state = true;
    return result;
  }

  protected openSaveModal(): void {
    if (this.validateFields()) {
      this.$bvModal.show('save-review-modal');
    }
  }

  protected openDefectEditModal(defect: ReviewDefect): void {
    this.editDefect = defect;
    this.$bvModal.show('edit-defect-modal');
  }

  protected openDefectDetailModal(defect: ReviewDefect): void {
    this.editDefect = defect;
    this.$bvModal.show('detail-defect-modal');
  }

  public openDefectCreationModal(): void {
    this.$bvModal.show('create-defect-modal');
  }

  public openDefectCreationHelpModal(): void {
    this.$bvModal.show('create-defect-help-modal');
  }

  public openDefectCreationModalWithArea(x: number, y: number, width: number, height: number): void {
    (this.$refs.createDefectModalRef as CreateDefectModal).setAreaDrawable(x, y, width, height);
    this.$bvModal.show('create-defect-modal');
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  protected getAllReviewDefects(): void {
    this.resetDefectsVisible();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ReviewDefectService.getAllReviewDefectsByReviewId(this.currentReview.id!)
      .then((result) => {
        this.reviewDefects = result.reverse();
        this.loaded = true;
      })
      .catch((backendError) => {
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast(
            'Failed to load',
            'Could not load review defects. ' + backendError.response.data.message,
            'danger'
          );
        }
      });
  }
}
