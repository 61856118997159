import { JsonProperty, Serializable } from 'typescript-json-serializer';
import { DefectTypeEnum } from '@/models/reviews/DefectTypeEnum';
import { DefectServityEnum } from '@/models/reviews/DefectServityEnum';

@Serializable()
export default class ReviewDefect {
  @JsonProperty('id')
  private _id?: number | undefined;
  @JsonProperty('startx')
  private _startx: number;
  @JsonProperty('starty')
  private _starty: number;
  @JsonProperty('width')
  private _width: number;
  @JsonProperty('height')
  private _height: number;
  @JsonProperty('commentReviewer')
  private _commentReviewer: string;
  @JsonProperty('positionInSpecification')
  private _positionInSpecification: string;
  @JsonProperty('defectType')
  private _defectType: DefectTypeEnum;
  @JsonProperty('defectSeverity')
  private _defectSeverity: DefectServityEnum;
  @JsonProperty('review')
  private _review: number;
  @JsonProperty('defectReportEval')
  private _defectReportEval: number | undefined;
  @JsonProperty('reported')
  private _reported: Date | undefined;

  constructor(
    startx: number,
    starty: number,
    width: number,
    height: number,
    commentReviewer: string,
    positionInSpecification: string,
    defectType: DefectTypeEnum,
    defectSeverity: DefectServityEnum,
    review: number
  ) {
    this._startx = startx;
    this._starty = starty;
    this._width = width;
    this._height = height;
    this._commentReviewer = commentReviewer;
    this._positionInSpecification = positionInSpecification;
    this._defectType = defectType;
    this._defectSeverity = defectSeverity;
    this._review = review;
  }

  get id(): number | undefined {
    return this._id;
  }

  set id(value: number | undefined) {
    this._id = value;
  }

  get startx(): number {
    return this._startx;
  }

  set startx(value: number) {
    this._startx = value;
  }

  get starty(): number {
    return this._starty;
  }

  set starty(value: number) {
    this._starty = value;
  }

  get width(): number {
    return this._width;
  }

  set width(value: number) {
    this._width = value;
  }

  get height(): number {
    return this._height;
  }

  set height(value: number) {
    this._height = value;
  }

  get commentReviewer(): string {
    return this._commentReviewer;
  }

  set commentReviewer(value: string) {
    this._commentReviewer = value;
  }

  get positionInSpecification(): string {
    return this._positionInSpecification;
  }

  set positionInSpecification(value: string) {
    this._positionInSpecification = value;
  }

  get defectType(): DefectTypeEnum {
    return this._defectType;
  }

  set defectType(value: DefectTypeEnum) {
    this._defectType = value;
  }

  get defectSeverity(): DefectServityEnum {
    return this._defectSeverity;
  }

  set defectSeverity(value: DefectServityEnum) {
    this._defectSeverity = value;
  }

  get review(): number {
    return this._review;
  }

  set review(value: number) {
    this._review = value;
  }

  get defectReportEval(): number | undefined {
    return this._defectReportEval;
  }

  set defectReportEval(value: number | undefined) {
    this._defectReportEval = value;
  }

  get reported(): Date | undefined {
    return this._reported;
  }

  set reported(value: Date | undefined) {
    this._reported = value;
  }
}
