import MainService from '@/services/MainService';
import { deserialize, serialize } from 'typescript-json-serializer';
import { AxiosResponse } from 'axios';
import ReviewDefect from '@/models/reviews/ReviewDefect';

export default class ReviewDefectService {
  private static mainURL = '/reviewdefect';

  public static getAllReviewDefectsByReviewId(id: number): Promise<ReviewDefect[]> {
    return MainService.get(this.mainURL + '/review/' + id).then((response) => {
      return response.data.map((item) => deserialize(item, ReviewDefect));
    });
  }

  public static getAllReviewDefectsByReviewAssignmentId(id: number | undefined): Promise<ReviewDefect[]> {
    return MainService.get(this.mainURL + '/reviewassignment/' + id).then((response) => {
      return response.data.map((item) => deserialize(item, ReviewDefect));
    });
  }

  public static getReviewDefectById(id: number): Promise<ReviewDefect> {
    return MainService.get(this.mainURL + '/' + id).then((response) => {
      return deserialize(response.data, ReviewDefect);
    });
  }

  public static saveReviewDefect(reviewDefect: ReviewDefect): Promise<ReviewDefect> {
    return MainService.post(this.mainURL, JSON.stringify(serialize(reviewDefect))).then((response) => {
      return deserialize(response.data, ReviewDefect);
    });
  }

  public static deleteReviewDefect(id: number): Promise<AxiosResponse> {
    return MainService.delete(this.mainURL + '/' + id);
  }
}
