



























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Model from '@/models/Model';
import ModelService from '@/services/ModelService';
import { fixNodeReferencesOfEdges, fixNodeReferencesOfNodeParentId } from '@/serializer/helpers';
import ModelScope from '@/models/ModelScope';
import { Action, State } from 'vuex-class';
import ModelBackgroundImage from '@/models/ModelBackgroundImage';
import ModelConfig from '@/models/ModelConfig';
import ModelConfigService from '@/services/ModelConfigService';
import SelectedModelElement from '@/models/SelectedModelElement';
import AssignmentAttributeBar from '@/components/review/AssignmentAttributeBar.vue';
import ReviewAssignment from '@/models/reviews/ReviewAssignment';
import ReviewAssignmentService from '@/services/ReviewAssignmentService';
import Point from '@/models/Point';
import ReviewBar from '@/components/review/ReviewBar.vue';
import Review from '@/models/reviews/Review';
import ReviewService from '@/services/ReviewService';
import ReviewCanvasReadOnly from '@/components/Editor/ReviewCanvasReadOnly.vue';
import { getCurrentUserID, getCurrentUserRoles, hasPermission } from '@/auth/AuthService';
import { PossibleAction } from '@/auth/PossibleAction';
import StoredReviewAssignment from '@/models/reviews/StoredReviewAssignment';
import AssignmentDetailsModal from '@/components/modals/AssignmentDetailsModal.vue';
import SidebarLeft from '@/components/review/SidebarLeft.vue';
import AttributeBar from '@/components/Editor/AttributeBar.vue';
import jsPDF from 'jspdf';
import UserInfo from '@/models/users/UserInfo';
import moment from 'moment';
import { UserRoleEnum } from '@/enums/UserRoleEnum';
import { AssignmentStateEnum } from '@/enums/AssignmentStateEnum';
import { ReviewStateEnum } from '@/enums/ReviewStateEnum';
import CanvasToolbarTop from '@/components/Editor/CanvasToolbar/CanvasToolbarTop.vue';
import Tag from '@/models/users/Tag';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import Config from '@/config';
import { ReviewAssignmentExportTypeEnum } from '@/enums/ReviewAssignmentExportTypeEnum';
import ExportHelper from '@/serializer/ExportHelper';
import ReviewDefectService from '@/services/ReviewDefectService';
import ReviewDefect from '@/models/reviews/ReviewDefect';

@Component({
  components: {
    CanvasToolbarTop,
    ReviewCanvasReadOnly,
    ReviewBar,
    AssignmentAttributeBar,
    AssignmentDetailsModal,
    SidebarLeft,
    AttributeBar,
  },
})
export default class AssignmentDetails extends mixins(Toasts) {
  protected readonly PossibleAction = PossibleAction;

  protected shouldBeVisible(action: PossibleAction, assignment?: ReviewAssignment | StoredReviewAssignment): boolean {
    const permission = hasPermission(action, undefined, assignment);
    return permission != null && permission;
  }

  @Action('setSelectedElements', { namespace: 'modelEditor' })
  protected setSelectedElements!: (selectedElements: SelectedModelElement[]) => void;

  @Action('setCurrentModel', { namespace: 'modelEditor' })
  protected setCurrentModel!: (model: Model | undefined) => void;

  @Action('setCurrentConfig', { namespace: 'modelEditor' })
  protected setCurrentConfig!: (config: ModelConfig | undefined) => void;

  @Action('setCurrentModelBackgroundImage', { namespace: 'modelEditor' })
  protected setCurrentModelBackgroundImage!: (backgroundImage: ModelBackgroundImage | undefined) => void;

  @Action('clearSelectedElements', { namespace: 'modelEditor' })
  protected clearSelectedElements!: () => void;

  @State('currentModel', { namespace: 'modelEditor' })
  protected currentModel!: Model;

  @State('currentConfig', { namespace: 'modelEditor' })
  protected currentConfig!: ModelConfig;

  @State('availableUsers', { namespace: 'userManagement' })
  protected availableUsers!: UserInfo[];

  @State('availableTags', { namespace: 'userManagement' })
  protected availableTags!: Tag[];

  @Prop({ required: true, default: '' })
  protected readonly projectId!: string;
  @Prop({ required: true, default: 0 })
  protected readonly assignmentId!: number;
  @Prop({ required: false, default: null })
  protected readonly reviewId!: number | null;

  protected showLeftBar = false;
  protected completeHideReviewBar = false;
  protected showAssignmentBar = false;

  protected currentAssignment: StoredReviewAssignment | null = null;
  protected currentModelScope: ModelScope | null = null;
  protected currentReviews: Review[] | null = null;

  protected reviewMode = true;
  protected currentReview: Review | null = null;

  protected currentUserRoles: UserRoleEnum[] = [];
  protected currentUserID = '';

  protected selectedModelElementIds: string[] = [];

  mounted(): void {
    if (hasPermission(PossibleAction.CAN_GET_ASSIGNMENT)) {
      this.currentUserRoles = getCurrentUserRoles();
      this.currentUserID = getCurrentUserID();

      this.$on('finished-delete-assignment', () => {
        this.returnToProject();
      });

      this.$on('finishedReviewCreation', (args) => {
        const review = args.review;
        this.$router.push({
          name: 'reviewProcess',
          params: { assignmentId: review.assignmentId, reviewId: review.id },
        });
      });

      this.$on('finishedUpdateAssignment', (args) => {
        if (this.currentAssignment) {
          this.currentAssignment.state = args.newState;
          this.currentAssignment.approved = true;
        }
      });

      this.loadCurrentAssignment();
    } else {
      this.$router.replace({
        name: 'projectDetails',
        params: { projectId: this.projectId },
      });
    }
  }

  beforeDestroy(): void {
    this.$off('finished-delete-assignment');
    this.$off('finished-review-creation');
    this.$off('finished-update-assignment');

    this.clearSelectedElements();
    this.setCurrentModel(undefined);
    this.setCurrentConfig(undefined);
    this.setCurrentModelBackgroundImage(undefined);
  }

  protected syncLeftSidebarVisibility(visible: boolean): void {
    this.showLeftBar = visible;
  }

  protected getBadgeColor(): string {
    let result = '';
    if (this.currentAssignment) {
      result = 'ml-3 rounded-pill text-light p-2 position-relative';
      switch (this.currentAssignment.state) {
        case AssignmentStateEnum.UNCLEAR:
        case AssignmentStateEnum.NOT_OK:
          return result + ' bg-danger';
        case AssignmentStateEnum.OK:
          return result + ' bg-success';
        default:
          return result + ' bg-secondary';
      }
    }
    return result;
  }

  protected getBadge(): string {
    let badge = '';
    if (this.currentAssignment) {
      badge = this.currentAssignment.state.toString();
      if (this.currentAssignment.state !== AssignmentStateEnum.IN_PROCESS) {
        badge += ' by ' + (this.currentAssignment.approved ? 'Owner' : 'Majority Voting');
      }
    }
    return badge;
  }

  protected loadCurrentAssignment(): void {
    this.reviewMode = this.$route.name === 'reviewProcess';
    if (hasPermission(PossibleAction.CAN_GET_ASSIGNMENT)) {
      this.setLoading(true);
      ReviewAssignmentService.getById(this.assignmentId)
        .then((storedAssignment) => {
          this.currentAssignment = storedAssignment;
          this.setCurrentModel(fixNodeReferencesOfNodeParentId(fixNodeReferencesOfEdges(this.currentAssignment.model)));
          this.currentModelScope = this.currentAssignment.modelScope;
          if (this.reviewMode) {
            this.loadCurrentReview();
          } else {
            this.loadCurrentReviewList();
          }
          this.showAssignmentBar = true;
        })
        .catch((backendError) => {
          this.setLoading(false);
          let msg,
            title = 'Failed to load';
          if (backendError.response === undefined) {
            msg = 'Could not find the queried assignment.';
          } else if (backendError.response.status === 403) {
            title = 'Action denied';
            msg = 'You do not have the required rights.';
          } else {
            msg = 'Oops, something failed: ' + backendError.response.status;
          }
          this.$router
            .replace({
              name: 'projectDetails',
              params: { projectId: this.projectId },
            })
            .finally(() => {
              this.showToast(title, msg, 'danger');
            });
        });
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected loadCurrentReviewList(): void {
    if (!this.reviewMode) {
      if (
        this.currentAssignment &&
        this.currentAssignment.id &&
        hasPermission(PossibleAction.CAN_GET_REVIEW, undefined, this.currentAssignment)
      ) {
        ReviewService.getAllByAssignmentId(this.currentAssignment.id)
          .then((reviews) => {
            this.currentReviews = reviews;
            if (
              this.currentUserRoles.indexOf(UserRoleEnum.ROLE_REVIEWER) > -1 &&
              this.currentUserRoles.indexOf(UserRoleEnum.ROLE_CONFIGURATOR) === -1 &&
              this.currentUserRoles.indexOf(UserRoleEnum.ROLE_ADMIN) === -1
            ) {
              this.completeHideReviewBar = reviews.length === 0;
            }
          })
          .catch((backendError) => {
            if (backendError.response.status === 403) {
              this.showToast('Action denied', 'You do not have the required rights.', 'danger');
            } else {
              this.showToast('Failed to load', 'Could not load the reviews.', 'danger');
            }
          })
          .finally(() => this.loadCurrentConfig());
      } else {
        this.showToast('Action denied', 'You do not have the required rights.', 'danger');
      }
    }
  }

  protected loadCurrentReview(): void {
    if (this.reviewMode) {
      if (
        this.reviewId &&
        this.currentAssignment &&
        hasPermission(PossibleAction.CAN_GET_REVIEW, undefined, this.currentAssignment)
      ) {
        ReviewService.getById(this.reviewId)
          .then((loadedReview) => {
            this.currentReview = loadedReview;
            this.loadCurrentConfig();
          })
          .catch((backendError) => {
            this.setLoading(false);
            let msg,
              title = 'Failed to load';
            if (backendError.response.status === 403) {
              title = 'Action denied';
              msg = 'You do not have the required rights.';
            } else {
              msg = 'Could not load the review.';
            }
            this.$router
              .replace({
                name: 'projectDetails',
                params: { projectId: this.projectId },
              })
              .finally(() => {
                this.showToast(title, msg, 'danger');
              });
          });
      } else {
        this.showToast('Action denied', 'You do not have the required rights.', 'danger');
      }
    }
  }

  protected loadAndExportAllReviewDefects(): void {
    ReviewDefectService.getAllReviewDefectsByReviewAssignmentId(this.currentAssignment?.id)
      .then((defects) => {
        if (this.currentAssignment != null && this.currentReviews != null) {
          this.createReviewDefectsCsvRows(defects).then((csvRows) => {
            const fileName =
              moment(Date.now()).format('YYYY-MM-DD-HHmm') +
              '-assignment-' +
              this.currentAssignment?.name
                .substring(0, Math.min(30, this.currentAssignment.name.length))
                .replace(/[^a-z0-9]/gi, '-')
                .toLowerCase() +
              '.csv';

            const csvString = csvRows.join('\n');

            const downloadLink = document.createElement('a');
            downloadLink.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
            downloadLink.setAttribute('download', fileName);

            downloadLink.style.display = 'none';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
        }
      })
      .catch((backendError) => {
        this.setLoading(false);
        let msg,
          title = 'Failed to load';
        if (backendError.response.status === 403) {
          title = 'Action denied';
          msg = 'You do not have the required rights.';
        } else {
          msg = 'Could not load the review.';
        }
        this.showToast(title, msg, 'danger');
      });
  }

  protected loadCurrentConfig(): void {
    if (hasPermission(PossibleAction.CAN_GET_CONFIG)) {
      ModelConfigService.getByID(this.currentModel.modelConfigId)
        .then((config) => {
          this.setCurrentConfig(config);
          if (this.currentModel && this.currentModel.hasImage) {
            if (hasPermission(PossibleAction.CAN_GET_IMAGE)) {
              this.loadImage()
                .then((imageHref: string) => {
                  this.setCurrentModelBackgroundImage(new ModelBackgroundImage(imageHref, new Point(0, 0)));
                })
                .catch(() => {
                  this.showToast(
                    'Failed to read',
                    'Oops, something went wrong while loading the background image',
                    'danger'
                  );
                })
                .finally(() => {
                  this.setLoading(false);
                  Vue.nextTick(() => {
                    this.selectModelScope();
                  });
                });
            } else {
              this.showToast('Action denied', 'You do not have the required rights.', 'danger');
              Vue.nextTick(() => {
                this.selectModelScope();
              });
            }
          } else {
            this.setLoading(false);
            Vue.nextTick(() => {
              this.selectModelScope();
            });
          }
        })
        .catch((backendError) => {
          this.setLoading(false);
          let msg,
            title = 'Failed to load';
          if (backendError.response.status === 404) {
            msg =
              'Could not find the config: ' +
              this.currentModel.modelConfig +
              ' ' +
              this.currentModel.modelConfigVersion;
          } else if (backendError.response.status === 403) {
            title = 'Action denied';
            msg = 'You do not have the required rights.';
          } else {
            msg = 'Oops, something failed: ' + backendError.response.status;
          }
          this.$router.replace({ name: 'projectDetails', params: { projectId: this.projectId } }).then(() => {
            this.showToast(title, msg, 'danger');
          });
        });
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected loadImage(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (this.currentModel && this.currentModel.id) {
        ModelService.getImageByModelId(this.currentModel.id).then((response) => {
          resolve(response);
        });
      } else {
        reject(false);
      }
    });
  }

  protected selectModelScope(): void {
    // do nothing
    if (this.currentAssignment) {
      const scope = this.currentAssignment.modelScope;
      const model = this.currentAssignment.model;

      for (const node of model.nodes) {
        if (scope.nodeIds.indexOf(node.id) !== -1) {
          this.selectedModelElementIds.push(node.id);
        }
      }

      for (const edge of model.edges) {
        if (scope.edgeIds.indexOf(edge.id) !== -1) {
          this.selectedModelElementIds.push(edge.id);
        }
      }

      for (const drawable of model.drawables) {
        if (scope.drawableIds.indexOf(drawable.id) !== -1) {
          this.selectedModelElementIds.push(drawable.id);
        }
      }
    }
  }

  protected deleteAssignment(): void {
    if (
      this.currentAssignment &&
      hasPermission(PossibleAction.CAN_DELETE_ASSIGNMENT, undefined, this.currentAssignment)
    ) {
      if (!this.reviewMode) {
        if (this.currentReviews && this.currentReviews.length > 0) {
          this.showToast('Delete not allowed', 'There are already existing reviews for this assignment.', 'danger');
        } else {
          this.$root.$emit('delete-assignment', { assignment: this.currentAssignment, target: this });
        }
      }
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected overrideState(assignment: ReviewAssignment): void {
    if (hasPermission(PossibleAction.CAN_UPDATE_ASSIGNMENT, undefined, assignment)) {
      this.$bvModal.show('override-modal');
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected startReviewProcess(): void {
    if (hasPermission(PossibleAction.CAN_SAVE_REVIEW)) {
      this.$root.$emit('start-review-process', { assignment: this.currentAssignment, target: this });
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected deleteReview(args: { review: Review }): void {
    const review: Review = args.review;
    if (review) {
      if (
        this.currentAssignment &&
        ReviewStateEnum[review.state] === ReviewStateEnum.IN_REVIEW &&
        hasPermission(PossibleAction.CAN_DELETE_REVIEW, undefined, this.currentAssignment, review)
      ) {
        this.$bvModal
          .msgBoxConfirm('Do you really want to delete this review?', {
            title: 'Delete Review',
            okVariant: 'danger',
            okTitle: 'Delete',
            cancelTitle: 'Cancel',
            hideHeaderClose: true,
            centered: true,
          })
          .then((confirmationResponse) => {
            if (confirmationResponse && review.id) {
              this.setLoading(true);
              ReviewService.delete(review.id)
                .then(() => {
                  this.showToast('Successfully deleted', 'Review was successfully deleted.', 'success');
                  if (this.currentReviews) {
                    this.currentReviews.splice(this.currentReviews.indexOf(review), 1);
                  }
                })
                .catch((backendError) => {
                  if (backendError.response.status === 403) {
                    this.showToast('Action denied', 'You do not have the required rights.', 'danger');
                  } else if (backendError.response.status === 409) {
                    this.showToast('Failed to delete', 'This review is already finished.', 'danger');
                  } else {
                    this.showToast(
                      'Failed to delete',
                      'Could not delete review: ' + backendError.response.status,
                      'danger'
                    );
                  }
                });
            }
          })
          .catch(() => {
            this.showToast('Unexpected behaviour', 'Oops, something failed...', 'danger');
          })
          .finally(() => this.setLoading(false));
      } else {
        if (ReviewStateEnum[review.state] !== ReviewStateEnum.IN_REVIEW) {
          this.showToast('Failed to delete', 'This review is already finished.', 'danger');
        } else {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        }
      }
    }
  }

  protected returnToProject(): void {
    this.$router.push({
      name: 'projectDetails',
      params: { projectId: this.projectId },
    });
  }

  protected exportAssignmentReport(): void {
    if (
      this.currentAssignment &&
      hasPermission(PossibleAction.CAN_EXPORT_ASSIGNMENT, undefined, this.currentAssignment)
    ) {
      const exportFile = new jsPDF();
      let marginTop = 30;
      exportFile.setFont('Helvetica', 'normal');
      exportFile.setFontSize(10);
      exportFile.text(
        'Exported: ' + moment(Date.now()).format('DD.MM.YYYY HH:mm') + ' by ' + this.getFullName(this.currentUserID),
        25,
        15
      );
      exportFile.setFont('Helvetica', 'bold');
      exportFile.setFontSize(18);
      marginTop = AssignmentDetails.writeTextWithWrapping(exportFile, 'Assigment Export', marginTop);
      exportFile.setFontSize(12);
      exportFile.setFont('Helvetica', 'bold');
      marginTop = AssignmentDetails.writeTextWithWrapping(exportFile, 'Name', marginTop);
      exportFile.setFont('Helvetica', 'normal');
      marginTop = AssignmentDetails.writeTextWithWrapping(exportFile, this.currentAssignment.name, marginTop - 3);
      exportFile.setFont('Helvetica', 'bold');
      marginTop = AssignmentDetails.writeTextWithWrapping(exportFile, 'Created', marginTop);
      exportFile.setFont('Helvetica', 'normal');
      marginTop = AssignmentDetails.writeTextWithWrapping(
        exportFile,
        moment(this.currentAssignment.created).format('DD.MM.YYYY HH:mm'),
        marginTop - 3
      );
      exportFile.setFont('Helvetica', 'bold');
      marginTop = AssignmentDetails.writeTextWithWrapping(exportFile, 'Assignment State', marginTop);
      exportFile.setFont('Helvetica', 'normal');
      marginTop = AssignmentDetails.writeTextWithWrapping(exportFile, this.getBadge(), marginTop - 3);
      if (this.currentAssignment && this.currentAssignment.tagIds.length > 0) {
        const tags: Tag[] = [];
        this.currentAssignment.tagIds.forEach((tagId) => {
          const tag = this.availableTags.find((tag) => tag.id === tagId);
          if (tag) {
            tags.push(tag);
          }
        });
        exportFile.setFont('Helvetica', 'bold');
        marginTop = AssignmentDetails.writeTextWithWrapping(exportFile, 'Tags', marginTop);
        exportFile.setFont('Helvetica', 'normal');
        marginTop = AssignmentDetails.writeTextWithWrapping(
          exportFile,
          tags.map((tag) => tag.name).join(', '),
          marginTop - 3
        );
      }
      exportFile.setFont('Helvetica', 'bold');
      marginTop = AssignmentDetails.writeTextWithWrapping(exportFile, 'Owner', marginTop);
      exportFile.setFont('Helvetica', 'normal');
      marginTop = AssignmentDetails.writeTextWithWrapping(exportFile, this.getOwnerNamesAsString(), marginTop - 3);
      exportFile.setFont('Helvetica', 'bold');
      marginTop = AssignmentDetails.writeTextWithWrapping(exportFile, 'Task Description', marginTop);
      exportFile.setFont('Helvetica', 'normal');
      marginTop = AssignmentDetails.writeTextWithWrapping(
        exportFile,
        AssignmentDetails.prepareText(this.currentAssignment.taskDescription, ReviewAssignmentExportTypeEnum.PDF),
        marginTop - 3
      );
      exportFile.setFont('Helvetica', 'bold');
      marginTop = AssignmentDetails.writeTextWithWrapping(exportFile, 'Method Instructions', marginTop);
      exportFile.setFont('Helvetica', 'normal');
      marginTop = AssignmentDetails.writeTextWithWrapping(
        exportFile,
        AssignmentDetails.prepareText(this.currentAssignment.methodInstructions, ReviewAssignmentExportTypeEnum.PDF),
        marginTop - 3
      );
      exportFile.setFont('Helvetica', 'bold');
      marginTop = AssignmentDetails.writeTextWithWrapping(exportFile, 'Materials', marginTop);
      exportFile.setFont('Helvetica', 'normal');
      marginTop = AssignmentDetails.writeTextWithWrapping(
        exportFile,
        AssignmentDetails.prepareText(this.currentAssignment.materials, ReviewAssignmentExportTypeEnum.PDF),
        marginTop - 3
      );

      exportFile.line(25, marginTop, 180, marginTop);

      exportFile.setFont('Helvetica', 'bold');
      marginTop = AssignmentDetails.writeTextWithWrapping(exportFile, 'Model Name', marginTop + 10);
      exportFile.setFont('Helvetica', 'normal');
      marginTop = AssignmentDetails.writeTextWithWrapping(exportFile, this.currentModel.name, marginTop - 3);
      exportFile.setFont('Helvetica', 'bold');
      marginTop = AssignmentDetails.writeTextWithWrapping(exportFile, 'Model Scope Description', marginTop);
      exportFile.setFont('Helvetica', 'normal');
      if (this.currentModelScope && this.currentModelScope.description) {
        marginTop = AssignmentDetails.writeTextWithWrapping(
          exportFile,
          this.currentModelScope.description,
          marginTop - 3
        );
      }

      if (this.currentReviews && this.currentReviews.length > 0) {
        exportFile.addPage();
        marginTop = 30;
        exportFile.setFont('Helvetica', 'bold');
        exportFile.setFontSize(18);
        marginTop = AssignmentDetails.writeTextWithWrapping(exportFile, 'Review Export', marginTop);
        for (const review of this.currentReviews) {
          exportFile.setFont('Helvetica', 'bold');
          exportFile.setFontSize(13);
          marginTop = AssignmentDetails.writeTextWithWrapping(
            exportFile,
            'Review by ' + this.getFullName(review.userId),
            marginTop
          );
          exportFile.setFont('Helvetica', 'normal');
          exportFile.setFontSize(12);
          marginTop = AssignmentDetails.writeTextWithWrapping(
            exportFile,
            'Created: ' + moment(review.created).format('DD.MM.YYYY HH:mm'),
            marginTop - 3
          );
          marginTop = AssignmentDetails.writeTextWithWrapping(
            exportFile,
            'State: ' + ReviewStateEnum[review.state],
            marginTop - 3
          );
          marginTop = AssignmentDetails.writeTextWithWrapping(
            exportFile,
            'Comment: ' +
              (review.comment
                ? AssignmentDetails.prepareText(review.comment, ReviewAssignmentExportTypeEnum.PDF)
                : '-'),
            marginTop - 3
          );
          marginTop += 3;
        }
      }

      exportFile.save(
        moment(Date.now()).format('YYYY-MM-DD') +
          '-assignment-' +
          this.currentAssignment.name
            .substring(0, Math.min(30, this.currentAssignment.name.length))
            .replace(/[^a-z0-9]/gi, '-')
            .toLowerCase() +
          '.pdf'
      );
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  public static prepareText(text: string, exportType: ReviewAssignmentExportTypeEnum): string {
    const setting = Config.EXPORT.STRIP_HTML_TAGS[exportType];
    if (setting) {
      text = ExportHelper.convertHTMLToText(text);
    }

    return text;
  }

  protected exportReviewsCsv(): void {
    if (
      this.currentAssignment &&
      hasPermission(PossibleAction.CAN_EXPORT_ASSIGNMENT, undefined, this.currentAssignment)
    ) {
      if (this.currentAssignment != null && this.currentReviews != null) {
        this.createReviewAssignmentCsvRows().then((csvRows) => {
          const fileName =
            moment(Date.now()).format('YYYY-MM-DD-HHmm') +
            '-assignment-' +
            this.currentAssignment?.name
              .substring(0, Math.min(30, this.currentAssignment.name.length))
              .replace(/[^a-z0-9]/gi, '-')
              .toLowerCase() +
            '.csv';

          const csvString = csvRows.join('\n');

          const downloadLink = document.createElement('a');
          downloadLink.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
          downloadLink.setAttribute('download', fileName);

          downloadLink.style.display = 'none';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        });
      }
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  private async createReviewDefectsCsvRows(
    reviewDefects: ReviewDefect[],
    delimiter = ';',
    quoteChar = '"'
  ): Promise<Array<string>> {
    const headerRow =
      quoteChar +
      [
        'Nr.',
        'MDRE-User',
        'Name',
        'Matriculation',
        'Assignment',
        'Reviewtype',
        'Start',
        'End',
        'Defect-ID',
        'Time',
        'Position',
        'Defect Type',
        'Defect Severity',
        'Defect Description',
        'Sum defects',
      ].join(quoteChar + delimiter + quoteChar) +
      quoteChar;

    if (this.currentAssignment && reviewDefects) {
      const escape = (it: string | null | undefined) => {
        return it ? it.replace(quoteChar, `\\${quoteChar}`) : '';
      };

      return Array.of(headerRow).concat(
        reviewDefects?.map((defect) => {
          return (
            quoteChar +
            [
              defect.review,
              this.getReviewWithId(defect.review) ? this.getReviewWithId(defect.review)?.userId : '-1',
              this.getReviewWithId(defect.review) ? this.getFullName(this.getReviewWithId(defect.review)?.userId) : '',
              this.getReviewWithId(defect.review)
                ? this.getMatriculationNum(this.getReviewWithId(defect.review)?.userId)
                : '',
              escape(this.currentAssignment?.name),
              'MDRE',
              this.getReviewWithId(defect.review)
                ? moment(this.getReviewWithId(defect.review)?.created).format('HH:mm:ss')
                : '-1',
              this.getReviewWithId(defect.review)
                ? moment(this.getReviewWithId(defect.review)?.modified).format('HH:mm:ss')
                : '-1',
              defect.id,
              defect.reported ? moment(defect.reported).format('HH:mm:ss') : '',
              escape(defect.positionInSpecification),
              defect.defectType,
              defect.defectSeverity,
              escape(defect.commentReviewer),
              this.getSumOfAllDefectsInReview(defect.review, reviewDefects),
            ].join(quoteChar + delimiter + quoteChar) +
            quoteChar
          );
        })
      );
    } else {
      return [headerRow];
    }
  }

  protected getSumOfAllDefectsInReview(reviewId: number, defects: ReviewDefect[]): number {
    let i = 0;
    for (const d of defects) {
      if (d.review === reviewId) {
        i++;
      }
    }
    return i;
  }

  protected getReviewWithId(reviewId: number): Review | null {
    if (this.currentReviews !== null) {
      for (const r of this.currentReviews) {
        if (r.id === reviewId) {
          return r;
        }
      }
    }
    return null;
  }

  private async createReviewAssignmentCsvRows(delimiter = ';', quoteChar = '"'): Promise<Array<string>> {
    const headerRow =
      quoteChar +
      [
        'reviewId',
        'projectId',
        'assignmentId',
        'assignmentName',
        'modelId',
        'modelName',
        'modelScopeId',
        'modelScopeDescription',
        'userId',
        'created',
        'modified',
        'state',
        'comment',
      ].join(quoteChar + delimiter + quoteChar) +
      quoteChar;

    if (this.currentAssignment && this.currentReviews) {
      const escape = (it: string | null | undefined) => {
        return it ? it.replace(quoteChar, `\\${quoteChar}`) : '';
      };

      return Array.of(headerRow).concat(
        this.currentReviews?.map((review) => {
          return (
            quoteChar +
            [
              review.id,
              this.currentAssignment ? this.currentAssignment.projectId : '',
              review.assignmentId,
              escape(this.currentAssignment?.name),
              this.currentModel.id,
              escape(this.currentModel.name),
              this.currentModelScope ? this.currentModelScope.id : '',
              escape(this.currentModelScope?.description),
              review.userId,
              review.created ? moment(review.created).format('YYYY-MM-DDTHH:mm:ss') : '',
              review.modified ? moment(review.modified).format('YYYY-MM-DDTHH:mm:ss') : '',
              review.state,
              escape(AssignmentDetails.prepareText(review.comment ?? '', ReviewAssignmentExportTypeEnum.CSV)),
            ].join(quoteChar + delimiter + quoteChar) +
            quoteChar
          );
        })
      );
    } else {
      return [headerRow];
    }
  }

  private getOwnerNamesAsString(): string {
    const owners: string[] = [];
    for (const user of this.availableUsers) {
      if (this.currentAssignment && this.currentAssignment.owners.indexOf(user.uid) > -1) {
        owners.push(user.fullName);
      }
    }
    return owners.join(', ');
  }

  private getFullName(uid: string | undefined): string {
    if (uid) {
      for (const user of this.availableUsers) {
        if (user.uid === uid) {
          return user.fullName;
        }
      }
    }
    return 'Unknown User';
  }

  private getMatriculationNum(uid: string | undefined): string {
    if (uid) {
      for (const user of this.availableUsers) {
        if (user.uid === uid) {
          return user.matriculationNumber;
        }
      }
    }
    return '0';
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  // from: https://stackoverflow.com/a/43611535
  private static writeTextWithWrapping(exportFile: jsPDF, text: string, marginTop: number): number {
    const splitTitle = exportFile.splitTextToSize(text, 153);
    let y = marginTop;
    for (let i = 0; i < splitTitle.length; i++) {
      if (y > 275) {
        y = 30;
        exportFile.addPage();
      }
      exportFile.text(splitTitle[i], 25, y);
      y = y + 6;
    }
    return y + 3;
  }
}
