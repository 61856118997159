

































































































































import { Component, Prop } from 'vue-property-decorator';
import AssignmentDetails from './AssignmentDetails.vue';
import AssignmentAttributeBar from '@/components/review/AssignmentAttributeBar.vue';
import ReviewAttributeBar from '@/components/review/ReviewAttributeBar.vue';
import ReviewCanvasReadOnly from '@/components/Editor/ReviewCanvasReadOnly.vue';
import ReviewChanges from '@/models/reviews/ReviewChanges';
import { hasPermission } from '@/auth/AuthService';
import ReviewService from '@/services/ReviewService';
import { PossibleAction } from '@/auth/PossibleAction';
import { ModelElementMarkerTypeEnum } from '@/enums/ModelElementMarkerTypeEnum';
import { Action, State } from 'vuex-class';
import ModelElementMarker from '@/models/ModelElementMarker';
import SidebarLeft from '@/components/review/SidebarLeft.vue';
import AttributeBar from '@/components/Editor/AttributeBar.vue';
import CanvasToolbarTop from '@/components/Editor/CanvasToolbar/CanvasToolbarTop.vue';
import ReviewCanvasDoReview from '@/components/Editor/ReviewCanvasDoReview.vue';
import ReviewDefect from '@/models/reviews/ReviewDefect';
import Drawable from '@/models/drawables/Drawable';
import DefectAreaDrawable from '@/models/drawables/DefectAreaDrawable';
import Point from '@/models/Point';
import Bounds from '@/models/Bounds';
import AreaDrawable from '@/models/drawables/AreaDrawable';

@Component({
  components: {
    ReviewCanvasDoReview,
    CanvasToolbarTop,
    ReviewCanvasReadOnly,
    ReviewAttributeBar,
    AssignmentAttributeBar,
    SidebarLeft,
    AttributeBar,
  },
})
export default class ReviewProcess extends AssignmentDetails {
  @Prop({ required: true, default: '' })
  protected readonly projectId!: string;
  @Prop({ required: true, default: 0 })
  protected readonly assignmentId!: number;
  @Prop({ required: false, default: null })
  protected readonly reviewId!: number | null;

  @Action('setSelectedMarkerType', { namespace: 'modelElementMarkers' })
  protected setSelectedMarkerType!: (elementMarker: ModelElementMarkerTypeEnum | null) => void;

  @State('selectedMarkerType', { namespace: 'modelElementMarkers' })
  protected selectedMarkerType!: ModelElementMarkerTypeEnum;

  @State('markers', { namespace: 'modelElementMarkers' })
  protected modelMarkers!: ModelElementMarker[];

  @Action('setMarkers', { namespace: 'modelElementMarkers' })
  protected setMarkers!: (markers: ModelElementMarker[]) => void;
  @Action('clearMarkers', { namespace: 'modelElementMarkers' })
  protected clearMarkers!: () => void;

  @Action('setReviewAssignmentId', { namespace: 'modelElementMarkers' })
  protected setReviewAssignmentId!: (value: number | null) => void;

  protected selectedModelElementIds: string[] = [];
  protected visibleDefectMarkers: Drawable[] = [];

  mounted(): void {
    this.setReviewAssignmentId(this.assignmentId);
  }

  beforeDestroy(): void {
    this.setReviewAssignmentId(null);
    this.clearMarkers();
  }

  protected submitReview(reviewChanges: ReviewChanges): void {
    if (hasPermission(PossibleAction.CAN_UPDATE_REVIEW) && this.reviewId) {
      this.setLoading(true);
      ReviewService.update(this.reviewId, reviewChanges)
        .then(() => {
          this.$router
            .push({
              name: 'assignmentDetails',
              params: { projectId: this.projectId, assignmentId: this.assignmentId.toString() },
            })
            .then(() => {
              this.showToast('Successfully submitted', 'Review was successfully submitted.', 'success');
            });
        })
        .catch((backendError) => {
          let msg,
            title = 'Failed to submit';
          if (backendError.response.status === 400) {
            msg = 'Review is already finished.';
          } else if (backendError.response.status === 403) {
            title = 'Action denied';
            msg = 'You do not have the required rights.';
          } else {
            msg = 'Could not submit the review: ' + backendError.response.status;
          }
          this.showToast(title, msg, 'danger');
        })
        .finally(() => this.setLoading(false));
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected openReviewDefectCreateModal(inputArea: AreaDrawable): void {
    (this.$refs.attributeBar as ReviewAttributeBar).openDefectCreationModalWithArea(
      inputArea.position.startPos.x,
      inputArea.position.startPos.y,
      inputArea.bounds.width,
      inputArea.bounds.height
    );
  }

  protected returnToAssignment(): void {
    this.$router.push({
      name: 'assignmentDetails',
      params: { projectId: this.projectId, assignmentId: this.assignmentId.toString() },
    });
  }

  protected resetVisibleDefects(): void {
    this.visibleDefectMarkers = [];
  }

  protected setVisibleDefect(defect: ReviewDefect): void {
    let areaDrawable = new DefectAreaDrawable(
      'AreaMarker',
      'Defect Marker',
      '',
      new Point(defect.startx, defect.starty),
      new Bounds(defect.width, defect.height)
    );
    areaDrawable.endPos = new Point(defect.startx + defect.width, defect.starty + defect.height);
    this.visibleDefectMarkers = [];
    this.visibleDefectMarkers.push(areaDrawable);
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  protected handleDesignImprovementMarker(): void {
    this.setSelectedMarkerType(ModelElementMarkerTypeEnum.DESIGN_IMPROVEMENT);
  }

  protected handleOkMarker(): void {
    this.setSelectedMarkerType(ModelElementMarkerTypeEnum.OK);
  }

  protected handleUnclearMarker(): void {
    this.setSelectedMarkerType(ModelElementMarkerTypeEnum.UNCLEAR);
  }

  protected handleErrorMarker(): void {
    this.setSelectedMarkerType(ModelElementMarkerTypeEnum.ERROR);
  }

  protected handleRemoveMarker(): void {
    this.setSelectedMarkerType(ModelElementMarkerTypeEnum.REMOVE);
  }

  protected handleStopMarkerMode(): void {
    this.setSelectedMarkerType(null);
  }

  protected handleRemoveAll(): void {
    this.$bvModal
      .msgBoxConfirm('Do you really want to remove all markers?', {
        title: 'Removing Process Marker',
        okTitle: 'Remove',
        okVariant: 'danger',
      })
      .then((response) => {
        if (response) {
          this.setMarkers([]);
          this.showToast('Successfully cleared', 'All markers of this review process are removed.', 'success');
        }
      })
      .catch(() => {
        this.showToast('Unexpected behaviour', 'Oops, something failed...', 'danger');
      });
  }

  protected selectModelScope(): void {
    // do nothing
    if (this.currentAssignment) {
      const scope = this.currentAssignment.modelScope;
      const model = this.currentAssignment.model;

      for (const node of model.nodes) {
        if (scope.nodeIds.indexOf(node.id) !== -1) {
          this.selectedModelElementIds.push(node.id);
        }
      }

      for (const edge of model.edges) {
        if (scope.edgeIds.indexOf(edge.id) !== -1) {
          this.selectedModelElementIds.push(edge.id);
        }
      }

      for (const drawable of model.drawables) {
        if (scope.drawableIds.indexOf(drawable.id) !== -1) {
          this.selectedModelElementIds.push(drawable.id);
        }
      }
    }
  }
}
