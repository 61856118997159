























import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import { BvModalEvent } from 'bootstrap-vue';

@Component({})
export default class DeleteReviewDefectModal extends mixins(Toasts) {
  @Prop({ required: true, type: Function })
  protected deleteDefect!: () => void;

  protected handleClose(e: BvModalEvent): void {
    this.$emit('close', e);
  }
}
