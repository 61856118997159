





























































import { Component, Prop } from 'vue-property-decorator';
import { BModal, BvModalEvent } from 'bootstrap-vue';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import { ReviewStateEnum } from '@/enums/ReviewStateEnum';

@Component({})
export default class SaveReviewModal extends mixins(Toasts) {
  //submitResult(state: ReviewStateEnum): void
  @Prop({ required: true, type: Function })
  protected submitResult!: (state: ReviewStateEnum) => void;

  public show(): void {
    (this.$refs.modal as BModal).show();
  }

  protected handleClose(e: BvModalEvent): void {
    this.$emit('close', e);
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  protected approveReview(): void {
    this.submitResult(ReviewStateEnum.OK);
  }

  protected rejectReview(): void {
    this.submitResult(ReviewStateEnum.NOT_OK);
  }

  protected unclearReview(): void {
    this.submitResult(ReviewStateEnum.UNCLEAR);
  }

  protected leaveReviewOpen(): void {
    this.submitResult(ReviewStateEnum.IN_REVIEW);
  }
}
