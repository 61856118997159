export default class ExportHelper {
  public static convertHTMLToText(string: string): string {
    // set line breaks after tags
    // end of tag
    string = string.replace(/(<\/(p|div|h1|h2|h3|ul|ol)>)/gi, '$1\n');
    // begin of tag
    string = string.replace(/(<(h1|h2|h3|ul|ol)>)/gi, '$1\n');
    // line break <br>
    string = string.replace(/(<br>)/gi, '$1\n');
    // line break <br /> or <br/>
    string = string.replace(/(<br([ ])?\/>)/gi, '$1\n');

    // strip tags
    const div = document.createElement('div');
    div.innerHTML = string;

    return div.textContent || div.innerText || '';
  }
}
