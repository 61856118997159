import { render, staticRenderFns } from "./CreateDefectModal.vue?vue&type=template&id=19a37b89&scoped=true&"
import script from "./CreateDefectModal.vue?vue&type=script&lang=ts&"
export * from "./CreateDefectModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19a37b89",
  null
  
)

export default component.exports