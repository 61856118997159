import { JsonProperty, Serializable } from 'typescript-json-serializer';
import { deserializeReviewState, serializeEnum } from '@/serializer/EnumHelpers';
import { ReviewStateEnum } from '@/enums/ReviewStateEnum';

@Serializable()
export default class ReviewChanges {
  @JsonProperty({
    name: 'state',
    onSerialize: serializeEnum,
    onDeserialize: deserializeReviewState,
  })
  private _state?: ReviewStateEnum;
  @JsonProperty('comment')
  private _comment?: string;

  constructor(state?: ReviewStateEnum, comment?: string) {
    this._state = state;
    this._comment = comment;
  }

  get state(): ReviewStateEnum | undefined {
    return this._state;
  }

  set state(value: ReviewStateEnum | undefined) {
    this._state = value;
  }

  get comment(): string | undefined {
    return this._comment;
  }

  set comment(value: string | undefined) {
    this._comment = value;
  }
}
