














































import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import { BModal, BvModalEvent } from 'bootstrap-vue';

@Component({})
export default class CreateDefectHelpModal extends mixins(Toasts) {
  @Prop({ required: true, type: Function })
  protected openDefectCreationModal!: () => void;

  protected handleClose(e: BvModalEvent): void {
    this.$emit('close', e);
  }

  public show(): void {
    (this.$refs.modal as BModal).show();
  }

  protected openFallbackDefectCreation(): void {
    this.openDefectCreationModal();
  }
}
