


























































































































import { Component, Prop } from 'vue-property-decorator';
import { BModal, BvModalEvent } from 'bootstrap-vue';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import ReviewDefect from '@/models/reviews/ReviewDefect';
import ReviewDefectService from '@/services/ReviewDefectService';
import moment from 'moment';

@Component({})
export default class DetailDefectModal extends mixins(Toasts) {
  @Prop({ required: true })
  protected defect!: ReviewDefect;

  protected fieldStates: {
    type?: boolean | null;
    severity?: boolean | null;
    commentReviewer?: boolean | null;
    positionInSpecification?: boolean | null;
  } = {
    type: null,
    severity: null,
    commentReviewer: null,
    positionInSpecification: null,
  };

  protected getState(state: boolean | null, value: string | null, maxLength: number): boolean | null {
    if (value && value.length > 0) {
      const lenCheck = value.length <= maxLength;
      if (!lenCheck) {
        return false;
      }
    }
    return state;
  }

  public show(): void {
    (this.$refs.modal as BModal).show();
  }

  protected handleClose(e: BvModalEvent): void {
    this.$emit('close', e);
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  protected format(date: Date): string {
    return moment(date).format('DD.MM.YYYY HH:mm');
  }
}
