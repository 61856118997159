
















































import { Component, Prop, Vue } from 'vue-property-decorator';
import moment from 'moment';
import Review from '@/models/reviews/Review';
import UserInfo from '@/models/users/UserInfo';
import { hasPermission } from '@/auth/AuthService';
import { PossibleAction } from '@/auth/PossibleAction';
import { State } from 'vuex-class';
import Project from '@/models/Project';
import ReviewAssignment from '@/models/reviews/ReviewAssignment';
import { ReviewStateEnum } from '@/enums/ReviewStateEnum';
import WysiwygEditor from '@/components/WysiwygEditor.vue';
@Component({
  components: { WysiwygEditor },
})
export default class ReviewBar extends Vue {
  protected readonly PossibleAction = PossibleAction;

  protected shouldBeVisible(action: PossibleAction, review?: Review): boolean {
    if (
      action === PossibleAction.CAN_DELETE_REVIEW &&
      review &&
      ReviewStateEnum[review.state] !== ReviewStateEnum.IN_REVIEW
    ) {
      return false;
    }
    const permission = hasPermission(action, this.currentProject, this.currentAssignment, review);
    return permission != null && permission;
  }

  @State('availableUsers', { namespace: 'userManagement' })
  protected availableUsers!: UserInfo[];

  @State('currentProject', { namespace: 'project' })
  protected currentProject!: Project;

  @Prop({ required: true, default: [] })
  protected reviews!: Review[];

  @Prop({ required: true })
  protected currentAssignment!: ReviewAssignment;

  protected getHeaderColor(state: ReviewStateEnum): string {
    switch (this.getReviewStateString(state)) {
      case ReviewStateEnum.OK:
        return 'success';
      case ReviewStateEnum.NOT_OK:
        return 'danger';
      case ReviewStateEnum.UNCLEAR:
        return 'warning';
      default:
        return 'primary';
    }
  }

  protected getReviewStateString(state: string): string {
    return ReviewStateEnum[state as keyof typeof ReviewStateEnum];
  }

  protected format(date: Date): string {
    return moment(date).format('DD.MM.YYYY HH:mm');
  }

  protected getName(uid: string): string {
    return (
      this.availableUsers
        .filter((value) => {
          if (value.uid === uid) {
            return value.fullName;
          }
        })
        .pop()?.fullName ?? ''
    );
  }

  protected openReviewDetails(review: Review): void {
    this.$router.push({
      name: 'reviewProcess',
      params: { assignmentId: '' + review.assignmentId, reviewId: '' + review.id },
    });
  }

  protected deleteReview(review: Review): void {
    this.$emit('delete-review', { review });
  }
}
