






















































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import ModelElement from '@/models/ModelElement';
import ReviewCanvas from '@/components/Editor/ReviewCanvas.vue';
import { ModelElementMarkerTypeEnum } from '@/enums/ModelElementMarkerTypeEnum';
import { mixins } from 'vue-class-component';
import ModelElementMarker from '@/models/ModelElementMarker';
import { Action } from 'vuex-class';
import Point from '@/models/Point';
import Intersects from 'intersects';
import Edge from '@/models/Edge';
import NodeColorHighlightSvg from '@/components/Editor/Highlight/NodeColorHighlightSvg.vue';
import EdgeColorHighlightSvg from '@/components/Editor/Highlight/EdgeColorHighlightSvg.vue';
import { VueSelecto } from 'vue-selecto';
import Drawable from '@/models/drawables/Drawable';
import Bounds from '@/models/Bounds';
import SelectedModelElement from '@/models/SelectedModelElement';
import DefectAreaDrawable from '@/models/drawables/DefectAreaDrawable';
import AreaDrawable from '@/models/drawables/AreaDrawable';

@Component({
  components: { NodeColorHighlightSvg, EdgeColorHighlightSvg, VueSelecto },
})
export default class ReviewCanvasDoReview extends mixins(ReviewCanvas) {
  @Action('addMarker', { namespace: 'modelElementMarkers' })
  protected addMarker!: (marker: ModelElementMarker) => void;

  @Action('setMarkers', { namespace: 'modelElementMarkers' })
  protected setMarkers!: (markers: ModelElementMarker[]) => void;

  @Action('loadMarkers', { namespace: 'modelElementMarkers' })
  protected loadMarkers!: () => void;

  @Prop({
    required: false,
    default: () => [],
  })
  protected highlightedModelElements!: string[];

  @Prop({
    required: true,
  })
  protected defectMarker: Drawable[] = [];

  @Prop({ required: true, type: Function })
  protected openCreateDefectModal!: (drawable: AreaDrawable) => void;

  mounted(): void {
    (this.$refs.canvas as HTMLElement).addEventListener('click', this.handleMarkerClick);
    this.loadMarkers();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-explicit-any
  handleDrag({ beforeTranslate, target, transform }: { beforeTranslate: any; target: any; transform: any }): void {
    // do nothing
    if (this.selectedElements[0].modelElement instanceof DefectAreaDrawable) {
      this.selectedElements[0].modelElement.startPos = new Point(beforeTranslate[0], beforeTranslate[1]);
    }
  }

  public handleDragGroup({ events }: { events: any }): void {
    // do nothing
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected handleDrop(e: Event): void {
    // do nothing
  }

  protected deleteSelectedElements(): void {
    if (this.selectedElements.length > 0) {
      this.defectMarker.splice(
        this.defectMarker.findIndex((marker) => marker === this.selectedElements[0].modelElement)
      );
    }
  }

  protected get resizable(): boolean {
    return true; // (this.selectedElements.length > 0 && this.selectedElements[0].modelElement instanceof DefectAreaDrawable);
  }

  protected get draggable(): boolean {
    return true;
  }

  protected handleMarkerClick(e: MouseEvent): void {
    if (this.isDragging) {
      return;
    }
    const drawContainer = this.$refs.drawContainer as HTMLDivElement;
    // bounds of drawContainer
    const bounds = drawContainer.getBoundingClientRect();

    // calculate position
    const x = e.clientX / (1 / this.scale) - bounds.x / (1 / this.scale) - this.internalViewPort.x;
    const y = e.clientY / (1 / this.scale) - bounds.y / (1 / this.scale) - this.internalViewPort.y;

    const elementBounds = 40 / (1 / this.scale);
    const offset = 20 / (1 / this.scale);
    const modelMarkers: ModelElementMarker[] = [];

    if (this.selectedMarkerType === ModelElementMarkerTypeEnum.REMOVE) {
      this.modelMarkers.forEach((marker: ModelElementMarker) => {
        if (
          !Intersects.boxBox(
            x - offset,
            y - offset,
            offset * (2 / (1 / this.scale)),
            offset * (2 / (1 / this.scale)),
            marker.position.x,
            marker.position.y,
            elementBounds,
            elementBounds
          )
        ) {
          modelMarkers.push(marker);
        }
      });
      this.setMarkers(modelMarkers);
    } else if (this.selectedMarkerType) {
      // either OK, DESIGN_IMPROVEMENT, ERROR or UNCLEAR
      this.addMarker(
        new ModelElementMarker(
          this.selectedMarkerType,
          new Point(x - elementBounds / (2 / (1 / this.scale)), y - elementBounds / (2 / (1 / this.scale)))
        )
      );
    }
  }

  protected isEdge(modelElement: ModelElement): boolean {
    return modelElement instanceof Edge;
  }

  protected modelElementIsSelected(modelElementId: string): boolean {
    return this.highlightedModelElements.indexOf(modelElementId) > -1;
  }

  protected onSelect(e) {
    const drawContainer = this.$refs.drawContainer as HTMLDivElement;

    // bounds of drawContainer
    const bounds = drawContainer.getBoundingClientRect();

    const x = e.rect.left / (1 / this.scale) - bounds.x / (1 / this.scale) - this.internalViewPort.x;
    const y = e.rect.top / (1 / this.scale) - bounds.y / (1 / this.scale) - this.internalViewPort.y;

    let width = e.rect.width / (1 / this.scale);
    let height = e.rect.height / (1 / this.scale);

    // console.log(width, height, x, y, this.scale, 1 / this.scale);

    if (width > 20 && height > 20) {
      let areaDrawable = new DefectAreaDrawable(
        'AreaMarker',
        'Defect Marker',
        '',
        new Point(x, y),
        new Bounds(width, height)
      );
      areaDrawable.endPos = new Point(x + width, y + height);
      this.defectMarker = [];
      this.defectMarker.push(areaDrawable);

      window.setTimeout(() => {
        if (areaDrawable.target) {
          this.setSelectedElements([new SelectedModelElement(areaDrawable, areaDrawable.target)]);
          Vue.nextTick(() => {
            this.openDefectCreateModal(areaDrawable);
          });
        }
      }, 200);
    }
  }

  protected openDefectCreateModal(drawable: AreaDrawable): void {
    this.openCreateDefectModal(drawable);
  }

  protected handleSelectoDragStart(e): void {
    const target = e.inputEvent.target;
    if ((this.$refs.moveable as any).isMoveableElement(target)) {
      e.stop();
    }
  }
}
