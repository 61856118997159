

















































































































import { Component, Prop } from 'vue-property-decorator';
import { BModal, BvModalEvent } from 'bootstrap-vue';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import ReviewDefect from '@/models/reviews/ReviewDefect';
import { DefectTypeEnum } from '@/models/reviews/DefectTypeEnum';
import { DefectServityEnum } from '@/models/reviews/DefectServityEnum';
import ReviewDefectService from '@/services/ReviewDefectService';
import ReviewChanges from '@/models/reviews/ReviewChanges';

@Component({})
export default class CreateDefectModal extends mixins(Toasts) {
  @Prop({ required: true })
  protected reviewId!: number;

  @Prop({ required: true, type: Function })
  protected getAllReviewDefects!: () => void;

  protected severity = '';
  protected type = '';
  protected defect = new ReviewDefect(0.0, 0.0, 0.0, 0.0, '', '', DefectTypeEnum.WRONG, DefectServityEnum.MINOR, -1);
  protected x = 0.0;
  protected y = 0.0;
  protected width = 0.0;
  protected height = 0.0;

  protected fieldStates: {
    type?: boolean | null;
    severity?: boolean | null;
    commentReviewer?: boolean | null;
    positionInSpecification?: boolean | null;
  } = {
    type: null,
    severity: null,
    commentReviewer: null,
    positionInSpecification: null,
  };

  protected getState(state: boolean | null, value: string | null, maxLength: number): boolean | null {
    if (value && value.length > 0) {
      const lenCheck = value.length <= maxLength;
      if (!lenCheck) {
        return false;
      }
    }
    return state;
  }

  public show(): void {
    (this.$refs.modal as BModal).show();
  }

  protected handleClose(e: BvModalEvent): void {
    this.getAllReviewDefects();
    this.resetAreaDrawable();
    this.$emit('close', e);
    this.severity = '';
    this.type = '';
    this.defect = new ReviewDefect(0.0, 0.0, 0.0, 0.0, '', '', DefectTypeEnum.WRONG, DefectServityEnum.MINOR, -1);
    this.fieldStates.type = null;
    this.fieldStates.positionInSpecification = null;
    this.fieldStates.commentReviewer = null;
    this.fieldStates.severity = null;
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  protected validateFields(): boolean {
    let result = true;
    if (this.type.length <= 0) {
      this.fieldStates.type = false;
      result = false;
    } else {
      this.fieldStates.type = true;
    }
    if (this.severity.length <= 0) {
      this.fieldStates.severity = false;
      result = false;
    } else {
      this.fieldStates.severity = true;
    }
    if (this.defect.commentReviewer.length <= 0 || this.defect.commentReviewer.length > 5000) {
      this.fieldStates.commentReviewer = false;
      result = false;
    } else {
      this.fieldStates.commentReviewer = true;
    }
    if (this.defect.positionInSpecification.length <= 0 || this.defect.positionInSpecification.length > 255) {
      this.fieldStates.positionInSpecification = false;
      result = false;
    } else {
      this.fieldStates.positionInSpecification = true;
    }
    return result;
  }

  public setAreaDrawable(x: number, y: number, width: number, height: number): void {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
  }

  protected saveDefect(e: BvModalEvent): void {
    this.defect.review = this.reviewId;
    this.defect.defectType = DefectTypeEnum[this.type];
    this.defect.defectSeverity = DefectServityEnum[this.severity];
    this.defect.startx = this.x;
    this.defect.starty = this.y;
    this.defect.width = this.width;
    this.defect.height = this.height;
    if (this.validateFields()) {
      this.setLoading(true);
      ReviewDefectService.saveReviewDefect(this.defect)
        .then(() => {
          this.setLoading(false);
          this.$emit('close');

          this.severity = '';
          this.type = '';
          this.defect = new ReviewDefect(0.0, 0.0, 0.0, 0.0, '', '', DefectTypeEnum.WRONG, DefectServityEnum.MINOR, -1);
          this.showToast('Success', 'Review Defect stored successfully.', 'success');
          this.fieldStates.type = null;
          this.fieldStates.positionInSpecification = null;
          this.fieldStates.commentReviewer = null;
          this.fieldStates.severity = null;
          this.getAllReviewDefects();
          this.resetAreaDrawable();
        })
        .catch((backendError) => {
          this.setLoading(false);
          if (backendError.response.status === 403) {
            this.showToast('Action denied', 'You do not have the required rights.', 'danger');
          } else {
            this.showToast('Failed to save', 'Could not save defect. ' + backendError.response.data.message, 'danger');
          }
        });
    } else {
      e.preventDefault();
    }
  }

  protected resetAreaDrawable(): void {
    this.x = 0.0;
    this.y = 0.0;
    this.width = 0.0;
    this.height = 0.0;
  }
}
