



































import { Component, Prop, Vue } from 'vue-property-decorator';
import ReviewDefect from '@/models/reviews/ReviewDefect';
import { DefectServityEnum } from '@/models/reviews/DefectServityEnum';

@Component({})
export default class ReviewDefectComponent extends Vue {
  @Prop({ required: true })
  protected defect!: ReviewDefect;
  @Prop({ required: true })
  protected hasbuttons!: boolean;
  @Prop({ required: true, type: Function })
  protected deleteReviewDefect!: (id: number) => void;
  @Prop({ required: true, type: Function })
  protected openEdit!: (defect: ReviewDefect) => void;
  @Prop({ required: false, type: Function })
  protected openDetails!: (defect: ReviewDefect) => void;

  protected getDefectPosition(): string {
    return this.defect.positionInSpecification.trim().substring(0, 13);
  }

  protected getSeverityString(): string {
    if (this.defect.defectSeverity === DefectServityEnum.MINOR) {
      return 'Minor';
    }
    if (this.defect.defectSeverity === DefectServityEnum.IMPORTANT) {
      return 'Important';
    }
    if (this.defect.defectSeverity === DefectServityEnum.CRITICAL) {
      return 'Critical';
    }
    return 'Minor';
  }

  protected getColorClassForSeverity(): string {
    if (this.defect.defectSeverity === DefectServityEnum.MINOR) {
      return 'minor';
    }
    if (this.defect.defectSeverity === DefectServityEnum.IMPORTANT) {
      return 'important';
    }
    if (this.defect.defectSeverity === DefectServityEnum.CRITICAL) {
      return 'critical';
    }
    return 'minor';
  }
}
