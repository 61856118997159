























































































































import { Component, Prop } from 'vue-property-decorator';
import { BModal, BvModalEvent } from 'bootstrap-vue';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import ReviewDefect from '@/models/reviews/ReviewDefect';
import ReviewDefectService from '@/services/ReviewDefectService';
import moment from 'moment';

@Component({})
export default class EditDefectModal extends mixins(Toasts) {
  @Prop({ required: true })
  protected defect!: ReviewDefect;

  @Prop({ required: true, type: Function })
  protected getAllReviewDefects!: () => void;

  protected fieldStates: {
    type?: boolean | null;
    severity?: boolean | null;
    commentReviewer?: boolean | null;
    positionInSpecification?: boolean | null;
  } = {
    type: null,
    severity: null,
    commentReviewer: null,
    positionInSpecification: null,
  };

  protected getState(state: boolean | null, value: string | null, maxLength: number): boolean | null {
    if (value && value.length > 0) {
      const lenCheck = value.length <= maxLength;
      if (!lenCheck) {
        return false;
      }
    }
    return state;
  }

  public show(): void {
    (this.$refs.modal as BModal).show();
  }

  protected handleClose(e: BvModalEvent): void {
    this.getAllReviewDefects();
    this.$emit('close', e);
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  protected validateFields(): boolean {
    let result = true;
    if (this.defect.defectType.length <= 0) {
      this.fieldStates.type = false;
      result = false;
    } else {
      this.fieldStates.type = true;
    }
    if (this.defect.defectSeverity.length <= 0) {
      this.fieldStates.severity = false;
      result = false;
    } else {
      this.fieldStates.severity = true;
    }
    if (this.defect.commentReviewer.length <= 0 || this.defect.commentReviewer.length > 5000) {
      this.fieldStates.commentReviewer = false;
      result = false;
    } else {
      this.fieldStates.commentReviewer = true;
    }
    if (this.defect.positionInSpecification.length <= 0 || this.defect.positionInSpecification.length > 255) {
      this.fieldStates.positionInSpecification = false;
      result = false;
    } else {
      this.fieldStates.positionInSpecification = true;
    }
    return result;
  }

  protected saveDefect(e: BvModalEvent): void {
    if (this.validateFields()) {
      this.setLoading(true);
      ReviewDefectService.saveReviewDefect(this.defect)
        .then(() => {
          this.setLoading(false);
          this.$emit('close');
          this.showToast('Success', 'Review Defect updated successfully.', 'success');
          this.fieldStates.type = null;
          this.fieldStates.positionInSpecification = null;
          this.fieldStates.commentReviewer = null;
          this.fieldStates.severity = null;
          this.getAllReviewDefects();
        })
        .catch((backendError) => {
          this.setLoading(false);
          if (backendError.response.status === 403) {
            this.showToast('Action denied', 'You do not have the required rights.', 'danger');
          } else {
            this.showToast('Failed to save', 'Could not save defect. ' + backendError.response.data.message, 'danger');
          }
        });
    } else {
      e.preventDefault();
    }
  }

  protected format(date: Date): string {
    return moment(date).format('DD.MM.YYYY HH:mm');
  }
}
